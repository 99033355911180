

.service-worker-list {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height:250px;
  padding-bottom: 10px;
  overflow-x: auto;
 
}
.auth{
 width:100%; 
}
.worker-container{
  width:100%;  height:250px; margin:0 auto; overflow-x:auto; overflow-y:hidden; 
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.worker-container::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}
.service-worker-card {
  background-color: #f1f1f1;
  border-radius: 2px;
  padding: 10px;
  margin: 10px;
  min-width: 250px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.service-worker-card.active {
  width: 300px;
  height:200px;
}
.service-worker-card.active:hover {
  transform: perspective(500px) rotateY(0deg);
}
.service-worker-card h3 {
  font-weight: bold;
}

.auth-methods {
  margin-top: 10px;
  padding: 4px;
  overflow:auto;
}

.auth-methods button {
  margin: 3px auto;
  width: 100%;
}

.auth-methods button:hover {
  opacity: 0.5;
}
