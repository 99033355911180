.account-component {
  animation: slide-in 0.3s ease;
}

h1 {
  color: #3366FF;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #F9FAFB;
  color: #2B2B2B;
}

tr:nth-child(even) {
  background-color: #F9FAFB;
}

tr:hover {
  background-color: #E2EDFF;
}

.account-component table td *{
  display:inline-block; line-height:50px; vertical-align: middle;
}
