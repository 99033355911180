.right-panel {
  width: 400px;
  height: 100vh;
  background-color: #f2f2f2;
  position: fixed;
  overflow-y:hidden;
  overflow-x:hidden;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}

.rtab {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #f2f2f2;
  color: #555;
  padding: 18px 8px;
  cursor: pointer;
  z-index: 999;
  transition: right 0.3s ease-in-out;
}
.rtab:hover { background:#FFF; }
.hide-button {
  position: absolute;
  top: 0px;
  width:100%;
  right: 10px;
  background-color: transparent;
  color: #555; 
  background:#CCC;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.right-panel .main{ padding-top:20px;  height:calc( 100vh - 50px); overflow:auto; }
.tab.visible {
  right: 0;
}
.logout{
  position:fixed;
  bottom:0; background:var(--delete-background); padding: 6px; width:100%; cursor:pointer; font-weight:bold; color:#fff;
}
.logout:hover{
  background:var(  --delete-hover-background);
}
