:root {
  --primary-color: #3366FF;
  --secondary-color: #F9FAFB;
  --text-color: #2B2B2B;
  --accent-color: #FF4D00;
  --background-color: #FFFFFF;
}

.dashboard {

  padding: 10px;
  font-family: Arial, sans-serif;
  animation: slide-in 0.3s ease;
}

.component-list {
  display: flex;
  overflow-y:hidden;
  overflow-x:auto;
  padding:18px;
  grid-gap: 20px;
  margin-top: 20px;
}

.component-card {
  position: relative;
  background-color: var(--secondary-color);
  border-radius: 2px;
  padding: 10px; min-width:150px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
}
.component-card:hover {
  transform: scale(1.15);  z-index:10;
  }

.Cloudflare .provider{ color:var(--secondary-color); background:linear-gradient(#f9a136,#c47611); padding:3px; margin-top:10px; font-weight: bold;}
.Akash .provider{ color:var(--secondary-color); background:linear-gradient(#af0f0f,#eb2020); padding:3px; margin-top:10px; font-weight: bold;} 
.Akash svg{ filter:brightness(100); height:20px; }
.AWS .provider{ color:var(--secondary-color); background:linear-gradient(#e2ba88,#eb9320); padding:3px; margin-top:10px; font-weight: bold;}
.provider{ border-radius: 2px; }
p {
  color: var(--text-color);
  margin: 0;
}

p.uptime {
  color: var(--accent-color);
  font-weight: bold;
}

p.status {
  color: var(--text-color);
  font-style: italic;
}
.uptime-bar {
  position: absolute;
  top: 5px;
  left: 0;
  height: 4px;
  border-radius: 8px;
  transition: width 0.5s;
}