
.input-container {
  margin-bottom: 10px;
}

.label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}



.button-container button:hover {
  background-color: #003a80;
}
