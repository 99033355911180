.api{  animation: slide-in 0.3s ease; }
.auth-container{ height:100%; }
.react-grid-item{ min-height: 400px; }
.configcom{
  position:relative;
  background-color: var(--api-summary-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  vertical-align: top;
  width:calc( 100% - 20px);
  height:calc( 100% - 20px);
  padding: 10px;
  min-width:400px;
}
.button-top button{ width:150px; }
.configcom .header{
  position:relative; background:var( --completion-bar-background); width:100%;
  margin-bottom:10px; padding:10px 0; font-weight:bold; color:var(--text-color);
  border-radius: 2px;
}
.deleteButton{
  position:absolute;
  right:10px; top:8px; background:rgb(49, 9, 9);
  z-index:10;
}
.function-editor button,
.api-update-container button{
width:100%;
}
.service-worker-card {

  position: relative;
  transform:perspective(500px) rotateY(20deg);
  transform-style: preserve-3d;
  transition: transform 0.3s;
}

.service-worker-card.active {

  position: relative; 
  transform: rotateY(0deg);
}
.service-worker-card:hover {
  transform:perspective(500px) rotateY(-20deg);
}

.service-worker-card:hover h3 {
  color:#FFF;
}
.service-worker-card .card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: transform 0.3s;
}

.service-worker-card:hover .card-content {
  transform: rotateY(0deg);
}


