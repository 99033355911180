.project-list-container {
  position:relative;
  margin: 20px auto; max-height:90vh; overflow:visible;
  max-width:900px;
}


button{ margin:3px; }
.project-list {
  display: flex;
  width:90%; 
  margin:0 auto;
  flex-direction: column;
  overflow:hidden;
}

.project {
  position:relative;
  display: flex;
  align-items: center;
  height:200px;
  width:85%;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px #e9e9e9;
  cursor: pointer;
  background-color:#f9f9f9;
  transform: perspective(500px) rotateX(20deg);
  border-radius: 2px;
  transition: transform 0.5s, background-color 0.5s;
}
.project:nth-of-type(1){
  width:70%; 
}
.project:nth-of-type(2){
  width:75%; 
}
.project:hover {
transform: perspective(500px) rotateX(0deg); background-color:#999;
box-shadow: 0 0px 3px #666;
color:#FFF;
  border-radius: 2px;
  transition: transform 0.5s;
}

.project-info {
  flex-grow: 1;
}
.project-info .networks{
  position: absolute;  bottom:0; right:0; padding:5px;

}
.project-info .networks div{
  display:inline-block; padding:5px;
}
.project button {
  margin-left: 10px;
}



.project.fade-down:nth-of-type(1){
  animation: fade2to1 0.5s ;
}
.project.fade-down:nth-of-type(2){
  animation: fade3to2 0.5s ;
}
.project.fade-down:nth-of-type(3){
  animation: fade4to3 0.5s ;
}

.project.fade-up:nth-of-type(1){
  animation: fadeup1 0.5s ;
}
.project.fade-up:nth-of-type(2){
  animation: fadeup2 0.5s ;
}
.project.fade-up:nth-of-type(3){
  animation: fadeup3 0.5s ;
}
.directions{
  position:absolute; right:10px; top:50%;
}
.directions button:hover{ background:#e9e9e9; }
@keyframes fadeup1 {
  from {
    opacity:0.5;
    width:60%;
    bottom:200px;
  }
  to {
    width:70%;
    bottom:0px;
    opacity:1;
  }
}
@keyframes fadeup2 {
  from {
    width:70%;
    opacity:0.5;
    bottom:200px;
  }
  to {
    width:75%;
    bottom:0px;
    opacity:1;
  }
}
@keyframes fadeup3 {
  from {
    width:75%;
    opacity:0.5;
    bottom:200px;
  }
  to {
    width:85%;
    bottom:0px;
    opacity:1;
  }
}

@keyframes fade1to0 {
  from {
    width:70%;
    opacity:0.5;
    bottom:-200px;
  }
  to {
    width:75%;
    bottom:0px;
    opacity:1;
  }
}

@keyframes fade2to1 {
  from {
    width:75%;
    opacity:1;
    bottom:-200px;
  }
  to {
    width:70%;
    bottom:0px;
    opacity:1;
  }
}

@keyframes fade3to2 {
  from {
    width:85%;
    bottom:-200px;
    opacity:0.5;
  }
  to {
    width:75%;
    bottom:0px;
    opacity:1;
  }
}

@keyframes fade4to3 {
  from {
    width:95%;
    bottom:-200px;
    opacity:0.5;
  }
  to {
    width:85%;
    bottom:0px;
    opacity:1;
  }
}





