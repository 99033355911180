.login-container {
position: relative;
max-width:600px;
  margin: 10% auto;
  padding: 0px;
  display: flex;
  background:transparent;
box-shadow: 0 0 2px #999;
  border-radius: 2px;

}
.backdrop{
  height:100vh; width:100vw; background:#e9e9e9; top:0; position:absolute;
}
.videobg{
  position:absolute; top:0; left:0;
  width: 100%; 
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  z-index:0;
}
.bg{
display: flex; min-width:300px;
  align-items: center;
  justify-content: center;
}

.login-container button{ background:#e9e9e9; color:#333; border:1px solid #333; padding:6px; }
.bg button *{ color:#333;}
.login-container  *{ max-width:100%; }
h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  font-size:13px;
  font-weight:bold;
}

.login-container div {
  display: block;
  text-align: left;
  font-weight:0;
}

input[type='text'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  background:#e9e9e9;
  border-radius: 2px;
}
@media screen and (max-width: 440px) {
.login-container {
  display: flex;
  flex-direction: column; /* Stack child elements vertically *//* Set the container height to the full viewport height */
}
}